import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLogOut } from "../context/Action";
import { useContext } from "react";
import { Context } from "../context/MainContext";
import logoIcon from "../assets/image/svg/logo.svg";
import { CardIcon, GraphIcon, UsersIcon, WalletIcon } from "./SidebarIcons";

const Sidebar = ({ drawer, setModalIsOpen }) => {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const links = [
    {
      name: "Users",
      url: "",
      img: <UsersIcon color={pathname === "/dashboard" ? "#fff" : ""} />,
    },
    {
      name: "Loan Request",
      url: "loan-request",
      img: (
        <GraphIcon color={pathname.includes("loan-request") ? "#fff" : ""} />
      ),
    },
    {
      name: "Loan Repayment",
      url: "loan-repayment",
      img: (
        <GraphIcon color={pathname.includes("loan-repayment") ? "#fff" : ""} />
      ),
    },
    {
      name: "Withdrawal Request",
      url: "withdrawal-request",
      img: (
        <WalletIcon
          color={pathname.includes("withdrawal-request") ? "#fff" : ""}
        />
      ),
    },
    {
      name: "Site Transactions",
      url: "site-transactions",
      img: (
        <CardIcon
          color={pathname.includes("site-transactions") ? "#fff" : ""}
        />
      ),
    },
    {
      name: "Defaulters",
      url: "defaulters",
      img: <UsersIcon color={pathname.includes("defaulters") ? "#fff" : ""} />,
    },
  ];
  const handleLogout = () => {
    localStorage.removeItem("zitchAdmin");
    dispatch(userLogOut());
    navigate("/login");
  };
  return (
    <div className={drawer ? "sidebar draw" : "sidebar"}>
      <div>
        <div className="sidebar__logo">
          <Link to="/dashboard">
            <img src={logoIcon} alt="" />
          </Link>
        </div>

        <h3>Menu</h3>
        <ul className="sidebar__list">
          {links.map((link) => {
            return (
              <Link
                key={link.name}
                to={link.url === "" ? "/dashboard" : "/dashboard/" + link.url}
                onClick={() => drawer && setModalIsOpen(false)}
              >
                <li
                  className={
                    pathname.includes(link.url) && link.name !== "Users"
                      ? "sidebar__list__item active"
                      : pathname === "/dashboard" && link.name === "Users"
                      ? "sidebar__list__item active"
                      : "sidebar__list__item"
                  }
                >
                  {link.img} {link.name}
                </li>
              </Link>
            );
          })}
        </ul>
      </div>

      <button className="sidebar__btn" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
