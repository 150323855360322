export const CardIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33301 7.60303H14.6663"
        stroke={color ? color : "#00101A"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.69853 14.603H4.29187C1.9252 14.603 1.3252 14.0164 1.3252 11.6764V6.19637C1.3252 4.07637 1.81855 3.39637 3.67855 3.2897C3.86521 3.28304 4.07187 3.27637 4.29187 3.27637H11.6985C14.0652 3.27637 14.6652 3.86303 14.6652 6.20303V9.14304"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 11.603H6.66667"
        stroke={color ? color : "#00101A"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 12.9367C14.6663 13.4367 14.5263 13.91 14.2797 14.31C13.8197 15.0834 12.973 15.6034 11.9997 15.6034C11.0263 15.6034 10.1797 15.0834 9.71969 14.31C9.47302 13.91 9.33301 13.4367 9.33301 12.9367C9.33301 11.4634 10.5263 10.27 11.9997 10.27C13.473 10.27 14.6663 11.4634 14.6663 12.9367Z"
        stroke={color ? color : "#00101A"}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9609 12.936L11.6209 13.596L13.0409 12.2827"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GraphIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2133 8.93648C13.9467 8.93648 14.6667 8.26981 14.0267 6.08315C13.5933 4.60981 12.3267 3.34315 10.8533 2.90981C8.66667 2.26981 8 2.98981 8 4.72315V6.64315C8 8.26981 8.66667 8.93648 10 8.93648H12.2133Z"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 10.7365C12.713 13.8232 9.75296 16.0632 6.38629 15.5165C3.85963 15.1099 1.82629 13.0765 1.41296 10.5499C0.872961 7.19653 3.09963 4.23653 6.17296 3.60986"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const WalletIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66699 6H4.66699"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6665 7.31327V8.68665C14.6665 9.05331 14.3731 9.35328 13.9998 9.36662H12.6931C11.9731 9.36662 11.3132 8.83995 11.2532 8.11995C11.2132 7.69995 11.3731 7.30662 11.6531 7.03328C11.8998 6.77995 12.2398 6.6333 12.6131 6.6333H13.9998C14.3731 6.64663 14.6665 6.94661 14.6665 7.31327Z"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.653 7.03349C11.373 7.30682 11.213 7.70016 11.253 8.12016C11.313 8.84016 11.973 9.36682 12.693 9.36682H13.9997V10.3335C13.9997 12.3335 12.6663 13.6668 10.6663 13.6668H4.66634C2.66634 13.6668 1.33301 12.3335 1.33301 10.3335V5.66683C1.33301 3.8535 2.42634 2.58683 4.12634 2.37349C4.29968 2.34683 4.47967 2.3335 4.66634 2.3335H10.6663C10.8397 2.3335 11.0063 2.34015 11.1663 2.36682C12.8863 2.56682 13.9997 3.84016 13.9997 5.66683V6.6335H12.613C12.2397 6.6335 11.8997 6.78015 11.653 7.03349Z"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UsersIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10671 7.24683C6.04004 7.24016 5.96004 7.24016 5.88671 7.24683C4.30004 7.1935 3.04004 5.8935 3.04004 4.2935C3.04004 2.66016 4.36004 1.3335 6.00004 1.3335C7.63337 1.3335 8.96004 2.66016 8.96004 4.2935C8.95337 5.8935 7.69337 7.1935 6.10671 7.24683Z"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9402 2.6665C12.2335 2.6665 13.2735 3.71317 13.2735 4.99984C13.2735 6.25984 12.2735 7.2865 11.0268 7.33317C10.9735 7.3265 10.9135 7.3265 10.8535 7.33317"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.77348 9.7065C1.16014 10.7865 1.16014 12.5465 2.77348 13.6198C4.60681 14.8465 7.61348 14.8465 9.44681 13.6198C11.0601 12.5398 11.0601 10.7798 9.44681 9.7065C7.62014 8.4865 4.61348 8.4865 2.77348 9.7065Z"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2266 13.3335C12.7066 13.2335 13.1599 13.0402 13.5332 12.7535C14.5732 11.9735 14.5732 10.6868 13.5332 9.90683C13.1666 9.62683 12.7199 9.44016 12.2466 9.3335"
        stroke={color ? color : "#00101A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
