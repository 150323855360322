import React, { useContext, useState } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Context } from "../context/MainContext";
import Loading from "./Loading";
import { approveWithdrawReq } from "../api/adminApi";

const TableMenuAlt = ({ id, setRefresh }) => {
  const { user } = useContext(Context);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [loading, setLoading] = useState(false);

  // const handleReject = async () => {
  //   if (user?.admin_key) {
  //     setLoading(true);
  //     const data = {
  //       admin_key: user?.admin_key,
  //       loan_id: id,
  //     };
  //     try {
  //       await rejectLoanReq(data);

  //       toast.success("Product deleted successfully");
  //       setModalIsOpen(false);
  //       setLoading(false);
  //       setRefresh((curr) => !curr);
  //     } catch (err) {
  //       setLoading(false);
  //       const message =
  //         (err.response && err.response.data && err.response.data.message) ||
  //         err.message ||
  //         err.toString();
  //       toast.error(message);
  //     }
  //   }
  // };

  const handleApprove = async () => {
    if (user?.admin_key) {
      setLoading(true);
      const data = {
        admin_key: user?.admin_key,
        withdrawal_id: id,
      };
      try {
        await approveWithdrawReq(data);

        toast.success("Withdrawal approved successfully");
        setModalIsOpen2(false);
        setLoading(false);
        setRefresh((curr) => !curr);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="Alt">
      {/* <span className="tableMenu__icon" ref={ref} {...anchorProps}>
        ...
      </span> */}

      <Menu
        menuButton={<span className="tableMenu__icon">...</span>}
        transition
      >
        <MenuItem onClick={() => setModalIsOpen2(true)}>Approve</MenuItem>
        {/* <MenuItem onClick={() => setModalIsOpen(true)}>Reject</MenuItem> */}
      </Menu>
      {/* <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        <Link to={"/dashboard/rooms/edit/" + id}>
          <MenuItem>Edit</MenuItem>
        </Link>
        <MenuItem onClick={() => setModalIsOpen(true)}>Delete</MenuItem>
      </ControlledMenu> */}

      {/* <Modal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cart Modal"
      >
        <div className="tableMenu__modal">
          <h4>Are you sure</h4>
          <p>You are about to Reject loan request</p>
          <div className="tableMenu__modal__btn">
            <button
              className="tableMenu__modal__btn__item"
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleReject}
              className="tableMenu__modal__btn__item delete"
            >
              {loading ? <Loading button={true} /> : "Reject"}
            </button>
          </div>
        </div>
      </Modal> */}

      <Modal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen2(false)}
        contentLabel="Cart Modal"
      >
        <div className="tableMenu__modal">
          <h4>Are you sure</h4>
          <p>You are about to Approve withdrawal request</p>
          <div className="tableMenu__modal__btn">
            <button
              className="tableMenu__modal__btn__item"
              onClick={() => setModalIsOpen2(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleApprove}
              className="tableMenu__modal__btn__item app"
            >
              {loading ? <Loading button={true} /> : "Approve"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TableMenuAlt;
