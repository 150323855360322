import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Context } from "../context/MainContext";
import { fetchLoanReq } from "../api/adminApi";
import TableMenu from "../components/TableMenu";
import { formattingOption } from "./WithdrawalRequest";

const LoanRequest = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [refresh, setRefresh] = useState(false);
  //   const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentLoans = loans.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(loans.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % loans.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const handleFetch = async () => {
      if (user?.admin_key) {
        const data = {
          admin_key: user?.admin_key,
        };
        setLoading(true);
        try {
          const res = await fetchLoanReq(data);
          console.log("res", res);
          setLoans(res.data?.all_loan_application);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.admin_key, refresh]);

  return (
    <div className="users">
      <h1 className="users__title">Loan Request</h1>

      <div className="users__table">
        <div className="users__table__body" style={{ minWidth: 1800 }}>
          <div className="users__table__row head">
            <div
              style={{ width: "30px" }}
              className="users__table__row__head"
            ></div>
            {/* <div style={{ width: "120px" }} className="users__table__row__head">
            First Name
          </div>

          <div style={{ width: "120px" }} className="users__table__row__head">
            Last Name
          </div> */}
            <div style={{ width: "250px" }} className="users__table__row__head">
              Email
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Eligibility Status
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Eligibility Amout
            </div>
            {/* <div style={{ width: "150px" }} className="users__table__row__head">
              Amount Approve
            </div> */}
            <div style={{ width: "150px" }} className="users__table__row__head">
              Approval Status
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Rejection Status
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Loan Status
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              Loan Due
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Loan Type
            </div>

            <div style={{ width: "100px" }} className="users__table__row__head">
              Interest Percetnage
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              Repayment months
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Schedule Repayment Amount
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              Total Repay
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              Action
            </div>
          </div>

          {loading ? (
            <Loading />
          ) : (
            currentLoans?.map((staff, i) => {
              return (
                <div key={staff?.id} className="users__table__row">
                  <div
                    style={{ width: "30px" }}
                    className="users__table__row__data"
                  >
                    {1 + i + itemOffset}
                  </div>
                  {/* <div
                  style={{ width: "120px" }}
                  className="users__table__row__data"
                >
                  {staff?.first_name}
                </div>
                <div
                  style={{ width: "120px" }}
                  className="users__table__row__data"
                >
                  {staff?.last_name}
                </div> */}
                  <div
                    style={{ width: "250px" }}
                    className="users__table__row__data"
                  >
                    <div className="users__table__row__data__user">
                      {/* <p style={{ margin: 0 }}>
                        {staff?.first_name} {staff?.last_name}
                      </p> */}
                      <span>{staff?.request_user}</span>
                    </div>
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.eligibility_status ? "True" : "False"}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {parseInt(staff?.eligibility_amount)?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}
                  </div>
                  {/* <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.amount_approve}
                  </div> */}
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.approval_status ? "True" : "False"}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.rejection_status ? "True" : "False"}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    <div
                      className={
                        staff?.active_loan
                          ? "users__table__row__data__loan active"
                          : "users__table__row__data__loan"
                      }
                    >
                      {staff?.active_loan ? "Active Loan" : "No Active Loan"}
                    </div>
                  </div>

                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {staff?.isdue_loan ? "True" : "False"}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.loan_type}
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {staff?.interest_percetnage}%
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {staff?.repayment_months}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {parseInt(staff?.schedule_repayment_amount)?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {parseInt(staff?.total_repay)?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    <TableMenu setRefresh={setRefresh} id={staff?.id} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {pageCount > 1 && (
        <div style={{ marginTop: 40 }}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            breakLabel="..."
            //   renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
            // pageRangeDisplayed={itemsPerPage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      )}
    </div>
  );
};

export default LoanRequest;
