import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/styles/scss/main.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-slideshow-image/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import DashboardLayout from "./layouts/DashboardLayout";
import Signin from "./pages/Signin";
import Users from "./pages/Users";
import LoanRequest from "./pages/LoanRequest";
import LoanRepayment from "./pages/LoanRepayment";
import WithdrawalRequest from "./pages/WithdrawalRequest";
import SiteTransaction from "./pages/SiteTransaction";
import Defaulters from "./pages/Defaulters";

function App() {
  return (
    <div className="App">
      <ToastContainer position="top-right" />
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/" element={<Signin />} />

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Users />} />
            <Route path="loan-request" element={<LoanRequest />} />
            <Route path="loan-repayment" element={<LoanRepayment />} />
            <Route path="withdrawal-request" element={<WithdrawalRequest />} />
            <Route path="site-transactions" element={<SiteTransaction />} />
            <Route path="defaulters" element={<Defaulters />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
