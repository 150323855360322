import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/admin";

export const fetchUsers = (data) => {
  return axios.post(`${apiURL}/get-all-users/`, data);
};

export const fetchLoanReq = (data) => {
  return axios.post(`${apiURL}/all-loan-request/`, data);
};

export const rejectLoanReq = (data) => {
  return axios.post(`${apiURL}/reject_loan/`, data);
};

export const approveLoanReq = (data) => {
  return axios.post(`${apiURL}/approve_loan/`, data);
};

export const fetchLoanRepay = (data) => {
  return axios.post(`${apiURL}/repayment_loan_history/`, data);
};

export const fetchWithdrawReq = (data) => {
  return axios.post(`${apiURL}/all-withdrawal-request/`, data);
};

export const approveWithdrawReq = (data) => {
  return axios.post(`${apiURL}/approve_withdrawal/`, data);
};

export const fetchSiteTran = (data) => {
  return axios.post(`${apiURL}/all-site-transactions/`, data);
};

export const scanDefaulters = (data) => {
  return axios.post(`${apiURL}/scandefaulters/`, data);
};
