import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Context } from "../context/MainContext";
import { fetchWithdrawReq } from "../api/adminApi";
import TableMenuAlt from "../components/TableMenuAlt";

export const formattingOption = {
  style: "currency",
  currency: "NGN",
  minimumFractionDigits: 2,
};

const WithdrawalRequest = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [refresh, setRefresh] = useState(false);
  //   const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  const currentLoans = loans.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(loans.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % loans.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const handleFetch = async () => {
      if (user?.admin_key) {
        const data = {
          admin_key: user?.admin_key,
        };
        setLoading(true);
        try {
          const res = await fetchWithdrawReq(data);
          console.log("res", res);
          setLoans(res.data?.all_withdrawal_request);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.admin_key, refresh]);

  return (
    <div className="users">
      <h1 className="users__title">Withdrawal Request</h1>

      <div className="users__table">
        <div className="users__table__body" style={{ minWidth: 1350 }}>
          <div className="users__table__row head">
            <div
              style={{ width: "30px" }}
              className="users__table__row__head"
            ></div>
            {/* <div style={{ width: "120px" }} className="users__table__row__head">
            First Name
          </div>

          <div style={{ width: "120px" }} className="users__table__row__head">
            Last Name
          </div> */}
            <div style={{ width: "250px" }} className="users__table__row__head">
              user email
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              Amount to Withdrawal
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              request date
            </div>
            {/* <div style={{ width: "150px" }} className="users__table__row__head">
              Amount Approve
            </div> */}
            <div style={{ width: "200px" }} className="users__table__row__head">
              user account name
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              user account number
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              user bank name
            </div>
            <div style={{ width: "150px" }} className="users__table__row__head">
              user wallet balance
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              withdrawal status
            </div>
            <div style={{ width: "100px" }} className="users__table__row__head">
              Action
            </div>
          </div>

          {loading ? (
            <Loading />
          ) : (
            currentLoans?.map((staff, i) => {
              return (
                <div key={staff?.id} className="users__table__row">
                  <div
                    style={{ width: "30px" }}
                    className="users__table__row__data"
                  >
                    {1 + i + itemOffset}
                  </div>
                  {/* <div
                  style={{ width: "120px" }}
                  className="users__table__row__data"
                >
                  {staff?.first_name}
                </div>
                <div
                  style={{ width: "120px" }}
                  className="users__table__row__data"
                >
                  {staff?.last_name}
                </div> */}
                  <div
                    style={{ width: "250px" }}
                    className="users__table__row__data"
                  >
                    <div className="users__table__row__data__user">
                      {/* <p style={{ margin: 0 }}>
                        {staff?.first_name} {staff?.last_name}
                      </p> */}
                      <span>{staff?.user_email}</span>
                    </div>
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {parseInt(staff?.amount_to_withdrawal)?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {staff?.request_date?.slice(0, 10)}
                  </div>
                  {/* <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.amount_approve}
                  </div> */}
                  <div
                    style={{ width: "200px" }}
                    className="users__table__row__data"
                  >
                    {staff?.user_account_name}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.user_account_number}
                  </div>
                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {staff?.user_bank_name}
                  </div>

                  <div
                    style={{ width: "150px" }}
                    className="users__table__row__data"
                  >
                    {parseInt(staff?.user_wallet_balance)?.toLocaleString(
                      "en-NG",
                      formattingOption
                    )}
                  </div>
                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    {staff?.withdrawal_status ? "True" : "False"}
                  </div>

                  <div
                    style={{ width: "100px" }}
                    className="users__table__row__data"
                  >
                    <TableMenuAlt setRefresh={setRefresh} id={staff?.id} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {pageCount > 1 && (
        <div style={{ marginTop: 40 }}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            breakLabel="..."
            //   renderOnZeroPageCount={null}
            onPageChange={handlePageClick}
            // pageRangeDisplayed={itemsPerPage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      )}
    </div>
  );
};

export default WithdrawalRequest;
