import { useContext, useState } from "react";
import { Context } from "../context/MainContext";
import { scanDefaulters } from "../api/adminApi";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

const Defaulters = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const handleScanDefaulters = async () => {
    if (user?.admin_key) {
      setLoading(true);
      const data = {
        admin_key: user?.admin_key,
        scandefaulter: 1,
      };
      try {
        await scanDefaulters(data);

        toast.success("Defaulters Scanned successfully");
        setLoading(false);
        // setRefresh((curr) => !curr);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };
  return (
    <div className="defaulters">
      <div className="defaulters__btn">
        <button className="btn" onClick={handleScanDefaulters}>
          {loading ? <Loading button={true} /> : "Scan Defalters"}
        </button>
      </div>
    </div>
  );
};

export default Defaulters;
