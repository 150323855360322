import { useContext, useEffect } from "react";
import { Context } from "../context/MainContext";
import { useNavigate, Outlet, Link } from "react-router-dom";
import searchIcon from "../assets/image/svg/search.svg";
import Sidebar from "../components/Sidebar";
import DrawerDB from "../components/DrawerDB";
import logoIcon from "../assets/image/svg/logo.svg";

const DashboardLayout = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="dashboardLayout">
      <nav className="dashboardLayout__topbar">
        <div className="dashboardLayout__topbar__logo">
          <Link to="/dashboard">
            <img src={logoIcon} alt="" />
          </Link>
        </div>
        <div className="dashboardLayout__topbar__search">
          <img src={searchIcon} alt="" />
          <input type="text" placeholder="Search" />
        </div>

        <div className="dashboardLayout__topbar__info">
          <DrawerDB />
        </div>
      </nav>

      <Sidebar />
      <div className="dashboardLayout__main">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
