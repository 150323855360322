import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Label } from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import { Context } from "../context/MainContext";
import { login } from "../api/usersApi";
import { userLoginSuccess } from "../context/Action";
import Loading from "../components/Loading";
import logo from "../assets/image/svg/logo.svg";
import eye from "../assets/image/svg/eye.svg";

const Signin = () => {
  const { dispatch, user } = useContext(Context);
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!password) {
      passwordError = "password is required, minimum 2 characters";
    }

    if (passwordError || emailError) {
      setInputError((curr) => {
        return {
          ...curr,
          password: passwordError,
          email: emailError,
        };
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        const data = {
          admin_key: password,
        };
        await login(data);

        setLoading(false);
        dispatch(userLoginSuccess(data));
        toast.success("Login successfully");
        navigate("/dashboard");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="signin">
      <img src={logo} className="signin__logo" alt="" />
      <div className="signin__main">
        <h2>Log in to Admin </h2>
        <div className="signin__main__inputCon">
          <Label htmlFor="password">
            {" "}
            Admin Key <span>*</span>
          </Label>

          <div className="signin__main__inputCon__pass">
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              error={inputError.password ? true : false}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              onClick={() => setShowPassword((curr) => !curr)}
              src={eye}
              alt=""
            />
          </div>
        </div>

        <br />

        <button style={{ width: "100%" }} className="btn" onClick={handleLogin}>
          {loading ? <Loading button={true} /> : "Sign in"}
        </button>
      </div>
    </div>
  );
};

export default Signin;
